import React, { useState, useEffect } from 'react'
import MainNav from '../navs/MainNav/MainNav'
import SubNav from '../navs/SubNav/SubNav'
import MobileNav from '../navs/MobileNav/MobileNav'
import { Link } from 'gatsby'
import { ReactComponent as Logo } from '../../images/egenesis-logo.svg'
import { useWindowSize } from '../../utils'

import './SiteHeader.scss'



export default function SiteHeader({ isHome }) {
  const width = useWindowSize()
  const [scrolled, setScrolled] = useState(false)
  const homeClass = isHome ? 'front-page' : '';

  const handleScroll = () => {
    const position = window.scrollY
    position > 20
      ? setScrolled(true)
      : setScrolled(false)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  }, [])

  return (
    <header className={`bg-indigo sticky w-full top-0 z-50 ${homeClass} scrolled-${scrolled}`}>
      <div className='top-headline py-3 xl:py-5'>
        <div className="container">
          <p className='font-normal'><Link className='font-semibold' to='/press-releases/egenesis-announces-second-patient-successfully-transplanted-with-genetically-engineered-porcine-kidney/'>Read More:</Link> eGenesis Announces Second Patient Successfully Transplanted with Genetically Engineered Porcine Kidney</p>
        </div>
      </div>
      <div className="container flex justify-between relative">
        <div className="logo">
          <Link to="/" className="relative bottom-2 mdlg:-bottom-4">
            <Logo />
          </Link>
        </div>

        <nav className="flex flex-wrap justify-end">
          {width >= 1120 ? (
            <>
              <SubNav />
              <MainNav />
            </>
          ) : (
            <>
              <MobileNav />
            </>
          )}
        </nav>
      </div>
    </header>
  )
}

